import {DolphinAPIClientProvider, SessionManager} from "../index";
import {API_ROUTES, getUAASServiceBaseURL} from "../utils/BaseURLUtils";
import DataHandler from "../handler/DataHandler";


export default {
    CONSENT_REQUEST_TYPES: {
        FETCH: "FETCH",
        UPDATE: "UPDATE"
    },

    CONSENT_TYPE: {
        INDUCT_PROCESS: "INDUCT_PROCESS",
        INDUCT_SUMMARY: "INDUCT_SUMMARY",
        STOW_PROCESS: "STOW_PROCESS",
        STOW_SUMMARY: "STOW_SUMMARY"
    },

    METRIC_QUERY_TYPE: {
        PROCESS: "PROCESS",
        SUMMARY: "SUMMARY"
    },

    async fetchUpdateConsentStatus(stage, region, requestType, consentType, isConsented) {
        const path = API_ROUTES.LMFS.FetchUpdateConsentStatusRequest;
        const body = {
            requestType: requestType,
            alias: DataHandler.getUserEmployeeLogin(),
            nodeId: DataHandler.getStationCode(),
            consentType: consentType,
            consented: isConsented
        };
        try {
            const LMFSClient = getLMFSClient(stage, region);
            const response = await LMFSClient.post(path, body, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (e) {
           console.error("Caught exception from LMFS for fetchUpdateConsentStatus -> ", e);
        }
    },

    async fetchInductMetrics(stage, region, queryType, startTimestamp, endTimestamp) {
        const path = API_ROUTES.LMFS.FetchInductMetricsRequest;
        const body = {
            queryType: queryType,
            alias: DataHandler.getUserEmployeeLogin(),
            nodeId: DataHandler.getStationCode(),
            startTimestamp: startTimestamp ?? Math.round(SessionManager.getSessionStartTimestamp() / 1000),
            endTimestamp: endTimestamp ?? Math.round(Date.now() / 1000)
        };
        try {
            const LMFSClient = getLMFSClient(stage, region);
            const response = await LMFSClient.post(path, body, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (e) {
            console.error("Caught exception from LMFS for fetchInductMetrics -> ", e);
        }
    },

    async fetchStowMetrics(stage, region, queryType, startTimestamp, endTimestamp) {
        const path = API_ROUTES.LMFS.FetchStowMetricsRequest;
        const body = {
            queryType: queryType,
            alias: DataHandler.getUserEmployeeLogin(),
            nodeId: DataHandler.getStationCode(),
            startTimestamp: startTimestamp ?? Math.round(SessionManager.getSessionStartTimestamp() / 1000),
            endTimestamp: endTimestamp ?? Math.round(Date.now() / 1000)
        };
        try {
            const LMFSClient = getLMFSClient(stage, region);
            const response = await LMFSClient.post(path, body, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (e) {
            console.error("Caught exception from LMFS for fetchStowMetrics -> ", e);
        }
    }
}

const getLMFSClient = (stage, region) => {
    // BaseURL for LMFS and UAAS is exactly same.
    DolphinAPIClientProvider.generateDolphinAPIClient(getUAASServiceBaseURL(stage, region));
    return DolphinAPIClientProvider.dolphinAPIClient;
};