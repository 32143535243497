import closeTokens from "@amzn/meridian-tokens/base/icon/close";
import Text from "@amzn/meridian/text";
import React from "react";
import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import PropTypes from "prop-types";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import './helpview.css';
import Divider from "@amzn/meridian/divider";
import {API_ROUTES, FeatureManager, LocationHelper, AssociateDashboardHelper, REDIRECT_HASH} from "@amzn/dolphin-web-framework";
import Constants from "./Constants";

export default function HelpView(props) {
    
    const isCreateHelpRequestOptionEnabled = () => {
        return !props.disableCreateHelpRequestOption &&
            FeatureManager.isFeatureEnabled(FeatureManager.Features.CREATE_HELP_REQUEST_ENABLED);
    }

    const renderCreateHelpRequestOption = () => {
        if (isCreateHelpRequestOptionEnabled()) {
            return (
                <div role="button" onClick={handleCreateHelpOptionClick} style={{cursor: "pointer"}}>
                    <Column spacingInset="medium" spacing={"none"}>
                        <Text
                            type="b200">{props.createHelpRequestOptionTitle ? props.createHelpRequestOptionTitle : Constants.ASK_FOR_URGENT_HELP}</Text>
                    </Column>
                </div>
            )
        }
        return null;
    }

    const getLocation = () => {
        const lastScannedLocation = LocationHelper.getLastScannedLocation();
        if (lastScannedLocation && lastScannedLocation.length !== 0) {
            return lastScannedLocation;
        }
        return Constants.DEFAULT_LOCATION;
    }

    const handleCreateHelpOptionClick = () => {
        if (props.stage && props.region) {
            AssociateDashboardHelper.redirectToAssociateDashboardModule(props.stage, props.region, Constants.PWA,
                {location: getLocation()}, REDIRECT_HASH.ASSOCIATE_EXPERIENCE.CreateNotificationRequest);
        }
    }
    
    const isHelpOptionsEmpty = () => {
        if (props.list) {
            return props.list.length === 0 && !isCreateHelpRequestOptionEnabled();
        }
        return !isCreateHelpRequestOptionEnabled();
    }
    
    const renderDefaultHelpScreen = () => {
        if (isHelpOptionsEmpty()) {
            return (
                <Column alignmentHorizontal="center" spacingInset={"medium"} spacing={"medium"}>
                    <Text type="b200" alignment="center">Please <b>see your station manager</b> for any immediate
                        help</Text>
                    <img src={props.help_icon} width="50%" alt={"no help options available"}/>
                </Column>
            );
        }
        return null;
    }

    return <Column className={"helpView"} width={"100%"} height={"100%"} backgroundColor="#ffffff">
        <Row backgroundColor="#252829" width="100%" widths={["fill"]} spacingInset="medium" spacing="none">
            <Text role="heading" type="h200" color={"inverted"} alignment={"center"}>{props.title}</Text>
            <div style={{position: "absolute", right: 0}}>
                <Row spacingInset={"small"} spacing={"none"}>
                    <Button label={"close"} type="icon" color={"inverted"} onClick={props.onClose}>
                        <Text color={"inverted"}><Icon tokens={closeTokens}/></Text>
                    </Button>
                </Row>
            </div>
        </Row>
        <Column width={"100%"} spacingInset={"medium"}>
            <Text type="h200">{props.pageTitle ? props.pageTitle : Constants.HELP_MENU}</Text>
            <Box spacing={"none"} type={"outline"}>
                {props.list && props.list.length ?
                    props.list.map((option, index) => {
                        return <div role="button" key={index} onClick={option.onClick} style={{cursor: "pointer"}}>
                            <Column spacingInset="medium" spacing={"none"}>
                                <Text type="b200">{option.title}</Text>
                            </Column>
                            <Divider size={"small"} spacingAfter={"none"} spacingBefore={"none"}/>
                        </div>
                    }) : null
                }
                {renderCreateHelpRequestOption()}
            </Box>
        </Column>
        {renderDefaultHelpScreen()}
    </Column>;
}

HelpView.propTypes = {
    title: PropTypes.string,
    pageTitle: PropTypes.string,
    onClose: PropTypes.func,
    help_icon: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        onClick: PropTypes.func
    })),
    stage: PropTypes.string,
    region: PropTypes.region,
    createHelpRequestOptionTitle: PropTypes.string,
    location: PropTypes.string,
    disableCreateHelpRequestOption: PropTypes.boolean
}